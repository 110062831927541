<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.financingSources') }}
    </template>
    <!-- /Title -->
    <!-- Breakdown Total Switcher -->
    <template #switcher>
      <breakdown-total-switcher
        class="switcher"
        @change="showTotals = $event"
      />
    </template>
    <!-- /Breakdown Total Switcher -->
    <!-- Chart -->
    <template #chart>
      <financing-sources-chart
        hide-title
        :chart-animations="chartAnimations"
        :show-totals="showTotals"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalAmount"
          :text="$t('charts.totalFinancingIncome')"
        />
        <div>
          <p class="m-0 text-small mb-4">
            {{ $t('charts.topThreeFinancingIncomes') }}
          </p>
          <template v-for="(finance, index) in topFinances">
            <year-info
              :key="index"
              class="mb-3"
              :avatar-text="index + 1"
              :text="finance.name"
              circle
              :money="getAmount(finance)"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
  </chart-component>
</template>

<script>
import BreakdownTotalSwitcher from '@/components/Charts/Breakdown/BreakdownTotalSwitcher'
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import FinancingSourcesChart from '@/views/Home/StoryMode/SetUp/Financing/Charts/FinancingSourcesChart'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'FinancingSources',

  components: {
    ChartComponentRightSide,
    YearInfo,
    ChartComponent,
    BreakdownTotalSwitcher,
    FinancingSourcesChart,
    TotalAmount
  },

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showTotals: false
    }
  },

  computed: {
    totalAmount () {
      return this.$store.state.idea.storyMode.setUp.financing.breakdown.totalFinancing
    },

    topFinances () {
      return this.$store.state.idea.storyMode.setUp.financing.breakdown.topFinancings
    }
  },

  methods: {
    getAmount (finance) {
      if (finance.type === 'loc') {
        return (finance.howManyTimes * finance.averageWithdrawalAmount)
      }

      return finance.amount
    }
  }
}
</script>
